<!--
 * @Author: your name
 * @Date: 2020-11-27 11:27:32
 * @LastEditTime: 2020-12-01 18:35:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\liveBroadcast\components\liveVideo.vue
-->
<template>
  <div class="wraper">
    <videoPlayer
      v-if="isLivePaly"
      :source="source"
      :config="hlsConfig"
      @close="handleVideoClose"
    ></videoPlayer>
    <div class="table-container">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        border
        :default-sort="{ prop: 'liveStartTime', order: 'descending' }"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="deviceName"
          align="center"
          min-width="160"
          label="直播摄像头"
        >
        </el-table-column>
        <el-table-column
          prop="liveStartTime"
          min-width="150"
          align="center"
          sortable
          label="直播时间"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          align="center"
          min-width="120"
          label="会员"
        >
        </el-table-column>
        <el-table-column
          prop="shareTime"
          min-width="150"
          align="center"
          label="分享时间"
        ></el-table-column>
        <el-table-column
          prop="visitedCount"
          align="center"
          min-width="110"
          label="浏览量"
        >
        </el-table-column>
        <el-table-column
          prop="videoRecordingUrl"
          min-width="168"
          align="center"
          label="直播视频内容"
        >
          <template slot-scope="scope">
            <img
              class="liveRead"
              @click="getLiveData(scope.row.id)"
              src="@/assets/images/live_broadcast/read.png"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column label="剪辑视频记录" min-width="128" align="center">
          <template slot-scope="scope">
            <span class="looks" @click="jumpClips(scope.row.id)">{{
              $t("read")
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import apis from "@/apis";
import LiveClass from "./videoClass";
import mixin from "@/mixins/index";
import videoPlayer from "./videoPlayer";

export default {
  mixins: [mixin],
  components: {
    videoPlayer,
  },
  props: {
    // Language: {
    //     required: false,
    //     type: Number,
    //     default: 0,
    // },
    params: {
        required: false,
        type: Object,
        default: () => {},
    },
  },
  data() {
    return {
      tableData: [],
      form: new LiveClass("form"),
      isLivePaly: false,
      source: "",
      hlsConfig: {},
      loading: false,
    };
  },
  computed: {},
  //data中的数据变化
  watch: {},
  methods: {
    searchData() {
      this.loading = true
      console.log(this.form, this.params)
      const formData = {
        ...this.form,
        ...this.params,
      };
      this.$http.post(apis.getliveBroadcastList, formData)
        .then((res) => {
          console.log("live", res);
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = true
        })
    },
    getLiveData(liveVideoId) {
      console.log(liveVideoId, this.TOKEN);
      this.play(
        `${apis.getLivePlayback}?liveVideoId=${liveVideoId}&token=${this.TOKEN}`,
      );
    },
    play(source, config) {
      this.source = source;
      this.hlsConfig = config
      this.isLivePaly = true;
    },
    handleVideoClose() {
      this.isLivePaly = false;
    },
    looks(id) {
      console.log(id);
    },
    jumpClips(id) {
      this.$router.push({
        path: '/liveBroadcast/editVideoRecord',
        query: { id },
      })
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "warning-row";
      }
      return "";
    },
    handleClearForm() {
      this.form = new LiveClass('form')
      this.tableData = []
    },
    handleSearchForm() {
      this.searchData()
    },
  },
  //创建完成
  created() {},
  //挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //创建之前
  beforeMount() {}, //挂载之前
  beforeUpdate() {}, //更新之前
  updated() {},
  beforeDestroy() {}, //销毁之前
  destroyed() {}, //销毁完成
  activated() {}, //keep-alive触发
};
</script>
<style lang='scss' scoped>
.table-container {
  .liveRead {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .looks {
    cursor: pointer;
  }
}
.page-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
}
</style>
