<!--
 * @Author: your name
 * @Date: 2020-11-26 11:01:47
 * @LastEditTime: 2020-12-03 15:03:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\liveBroadcast\shareRecord.vue
-->
<template>
  <div class="shareRecord">
    <el-tabs v-model="tab" @tab-click="tabClick($event)">
      <div>
        <el-row>
          <el-col :span="24">
            <el-form ref="form" label-width="100px">
              <el-col :span="5">
                <el-form-item
                  prop="mainDirection"
                  label="摄像头"
                  label-width="100px"
                >
                  <el-select v-model="params.deviceId">
                    <el-option
                      v-for="item in cameraList"
                      :key="item.id"
                      :label="item.deviceName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item prop="userName" label="会员姓名">
                  <el-input
                    clearable
                    v-model="params.userName"
                    placeholder="请输入会员姓名"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" style="text-align: center">
                <el-button
                  @click="searchEvent"
                  type="primary"
                  size="small"
                  style="width: 90px"
                  >{{ $t("search") }}</el-button
                >
                <el-button
                  @click="cleanFormAndLoad"
                  type="primary"
                  size="small"
                  style="width: 90px"
                  >{{ $t("clean") }}</el-button
                >
              </el-col>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <el-tab-pane label="直播视频" name="1">
        <live :params="params" ref="lives"></live>
      </el-tab-pane>
      <el-tab-pane label="剪辑视频" name="2">
        <clips :params="params" ref="clips"></clips>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import apis from "@/apis";
import { mapState } from "vuex";
import Live from "./components/liveVideo";
import Clips from "./components/clipsVideo";

export default {
  components: {
    Live,
    Clips,
  },
  data() {
    return {
      cameraList: [],
      params: {
        userName: '',
        deviceId: null,
      },
      tab: "1",
    };
  },
  computed: {
    ...mapState(["Language", "TOKEN", "venueId"]),
  },
  //data中的数据变化
  watch: {},
  methods: {
    getCamera() {
      this.$http
        .get(`${apis.getLiveCameraList}?venueId=${this.venueId}`)
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.cameraList = res.data.data;
          } else {
            this.$message.info(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    searchEvent() {
      if (this.tab === "1") {
        this.$refs["lives"].handleSearchForm();
      } else if (this.tab === "2") {
        console.log(this.form);
        this.$refs["clips"].handleSearchForm();
      }
    },
    clearParams() {
      this.params.userName = ''
      this.params.deviceId = null
    },
    cleanForm() {
      if (this.tab === "1") {
        this.$refs["lives"].handleClearForm();
      } else if (this.tab === "2") {
        this.$refs["clips"].handleClearForm();
      }
    },
    cleanFormAndLoad() {
      this.clearParams();
      this.cleanForm();
      this.searchEvent();
    },
    tabClick(event) {
      console.log(event);
      // this.tab = event.name
      this.cleanForm();
      this.searchEvent();
    },
    // toggle(clipsId) {
    //   console.log(clipsId);
    //   this.tab = "2";
    //   const clip = this.$refs["clips"];
    //   clip.handleClearForm();
    //   this.form.deviceId = clipsId;
    //   this.$refs["clips"].searchDataById(clipsId);
    // },
  },
  //创建完成
  created() {
    this.getCamera();
  },
  //挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='scss' scoped>
.shareRecord {
  padding: 5px 20px 20px;
  .page-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
  }
  .el-tabs__active-bar {
    display: none;
  }
}
</style>
