<!--
 * @Author: your name
 * @Date: 2020-11-27 11:27:32
 * @LastEditTime: 2020-12-03 15:10:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\liveBroadcast\components\liveVideo.vue
-->
<template>
  <div class="wraper">
    <div class="table-container">
      <el-table
        v-loading = "loading"
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        :default-sort="{ prop: 'liveStartTime', order: 'descending' }"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="userName"
          align="center"
          min-width="120"
          type="index"
          label="序号"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          min-width="150"
          align="center"
          label="会员名称"
        >
        </el-table-column>
        <el-table-column
          prop="shareTime"
          min-width="150"
          align="center"
          label="分享时间"
          sortable
        ></el-table-column>
        <el-table-column
          prop="visitedCount"
          align="center"
          min-width="120"
          label="浏览量"
        >
        </el-table-column>
        <el-table-column
          prop="liveVideoUrl"
          min-width="168"
          align="center"
          label="剪辑视频内容"
        >
          <template slot-scope="scope">
            <img
              class="liveClips"
              @click="liveClips(scope.row.clipVideoUrl)"
              src="@/assets/images/live_broadcast/clips.png"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="memo"
          label="备注"
          min-width="160"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
    <!-- <video-player ref="videoPlayer" :livePlay="livePlay" :options="videoOptions"></video-player> -->
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import apis from "@/apis";
import LiveClass from "./videoClass";
import mixin from "@/mixins/index";

export default {
  mixins: [mixin],
  components: {
  },
  props: {
    // Language: {
    //     required: false,
    //     type: Number,
    //     default: 0,
    // },
    params: {
        required: false,
        type: Object,
        default: () => {},
    },
  },
  data() {
    return {
      livePlay: false,
      // videoOptions: {
      //     autoplay: true,
      //     controls: true,
      // },
      tableData: [],
      form: new LiveClass("form"),
      loading: false,
    };
  },
  computed: {},
  //data中的数据变化
  watch: {},
  methods: {
    searchData() {
      this.loading = true
      const formData = {
        ...this.form,
        ...this.params,
      };
      console.log("formData", formData);
      this.$http.post(apis.getliveClipstList, formData)
        .then((res) => {
          console.log("clips", res);
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    liveClips(url) {
      console.log(url);
      this.livePlay = true;
      // this.$refs.videoPlayer.livePlay
      // this.$refs.videoPlayer.player
      // myPlayer.src([
      //     {
      //         type: "application/x-mpegURL",
      //         src: "http://ivi.bupt.edu.cn/hls/cctv3hd.m3u8", //CCTV3频道
      //     },
      // ]);
      this.$emit("clipsPlay", url);
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "warning-row";
      }
      return "";
    },
    handleClearForm() {
      this.form = new LiveClass('form')
      this.tableData = []
    },
    handleSearchForm() {
      this.searchData()
    },
  },
  //创建完成
  created() {},
  //挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //创建之前
  beforeMount() {}, //挂载之前
  beforeUpdate() {}, //更新之前
  updated() {},
  beforeDestroy() {}, //销毁之前
  destroyed() {}, //销毁完成
  activated() {}, //keep-alive触发
};
</script>
<style lang='scss' scoped>
.table-container {
  .liveClips {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.page-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
}
.el-tabs__active-bar {
  display: none;
}
</style>
